import React from "react";
import { Link } from "gatsby";
import { Layout } from "../../components";

const KampanieZagraniczne = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Kampanie zagraniczne",
        href: pathname,
        lang: "pl"
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/offer/international-campaigns/",
      }}
    >
      <section className="container-fluid campaign_section">
        <div className="text-center">
          <h1>Kampanie zagraniczne</h1>
        </div>
        <div className="row campaign_row">
          <div className="col-md-10 m-auto">
            <p className="text-center">
              Rozszerzasz swoją działalność i planujesz podbić zagraniczne
              rynki? A może już od dawna prowadzisz swoje biznesy w innych
              państwach i zastanawiasz się nad intensyfikacją działań?
            </p>
            <p className="text-center">
              Kampanie zagraniczne to jeden z najlepszych sposobów na
              zwiększenie obszaru działania firmy i dotarcie do zainteresowanych
              Twoimi usługami klientów nie tylko w Europie, ale niemal na całym
              świecie. Wnikliwa analiza gwarantuje dokładne sprawdzenie
              potencjału konkretnego rynku jeszcze przed rozpoczęciem
              jakichkolwiek działań, dzięki czemu są one celowane w najbardziej
              chłonny i umożliwiający generowanie największych zysków kraj.
            </p>
            <p className="text-center">
              Poznaj państwa, w których wyświetlaliśmy reklamy:
            </p>
          </div>
        </div>
        <div className="row campaign_row2">
          <div className="col-md-9 m-auto">
            <div className="text-center">
              <img
                className="img-fluid"
                src={require("../../assets/img/offer/campagne_map.svg")}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="row campaign_row2">
          <div className="col-md-9 m-auto">
            <p className="text-center">
              Nasze kampanie zagraniczne prowadzimy przez różne kanały: Google
              Ads, Facebook Ads, LinkedIn Ads, Seznam (Sklik). W zależności od
              Twojego celu dobieramy właściwy kanał, opracowujemy sprzedażowe
              komunikaty we współpracy z native speakerami, dzięki czemu masz
              pewność, że język, którym się posługujemy, jest poprawny. Nad
              kampaniami czuwają nasze zespoły: SEM (Search Engine Marketing)
              oraz Social Media, a certyfikowani specjaliści z wielką radością
              podbijają nowe rynki!
            </p>
          </div>
        </div>
      </section>

      <section className="container-fluid case_study_section">
        <h2>Case study</h2>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <Link
              to="/case-study/kampania-adwords-dla-branzy-turystycznej"
              className="portfolio_tile case"
            >
              <h3 className="case">
                Kampania AdWords <br /> dla branży turystycznej
              </h3>
              <div
                className="portfolio_tile-back case"
                style={{
                  backgroundImage: `url(${require("../../assets/img/offer/case_study_turystyczna_back.png")})`
                }}
              ></div>
              <img
                className="img-fluid"
                src={require("../../assets/img/offer/case_study_turystyczna_front.png")}
                alt=""
              />
            </Link>
          </div>
          <div className="col-lg-4 col-md-6">
            <Link
              to="/case-study/matrace-market"
              className="portfolio_tile case"
            >
              <h3 className="case">Matrace Market</h3>
              <div
                className="portfolio_tile-back case"
                style={{
                  backgroundImage: `url(${require("../../assets/img/offer/case_study_matrace_back.png")})`
                }}
              ></div>
            </Link>
          </div>
          <div className="col-lg-4 col-md-6">
            <Link to="/case-study/bananki" className="portfolio_tile case">
              <h3 className="case">Bananki</h3>
              <div
                className="portfolio_tile-back case"
                style={{
                  backgroundImage: `url(${require("../../assets/img/offer/case_study_bananki_back.png")})`
                }}
              ></div>
              <img
                className="img-fluid"
                src={require("../../assets/img/offer/case_study_bananki_front.png")}
                alt=""
              />
            </Link>
          </div>
        </div>
      </section>

      <section className="container-fluid section_services">
        <div className="text-center">
          <h2>Wszystkie usługi</h2>
        </div>
        <div className="row">
          <div className="col-md-2 box">
            <span>Banery reklamowe</span>
            <Link to="/oferta/banery-reklamowe/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Analityka internetowa</span>
            <Link to="/oferta/analityka-internetowa/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Social Media</span>
            <Link to="/oferta/social-media/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Linkedin Ads</span>
            <Link to="/oferta/linkedin-ads/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Facebook Ads</span>
            <Link to="/oferta/facebook-ads/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Google Ads</span>
            <Link to="/oferta/ads-google/">zobacz</Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default KampanieZagraniczne;
